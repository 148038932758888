<template>
  <div>
    <div v-if="thisActive == 1" class="software">
      <div class="menu bg-cover">
        <div>
          <div class="content">
            <p>Smart Superv</p>
            <h2>Unity 3DPlugIn</h2>
            <h3>3D video player that takes you to the 3D world</h3>
          </div>
          <a href="./static/software/3DPlugIn.zip">
            <el-button size="medium " type="success">Download</el-button></a
          >
        </div>
        <div class="rigth">
          <img
            style="width: 55%; max-width: 240px"
            src="@/assets/img/product/software/login.png"
            alt=""
          />
        </div>
      </div>

      <old-to-new></old-to-new>
      <div class="line-B"></div>
      <div class="update">
        <div class="update-title">Update log:</div>
        <el-collapse accordion>
          <el-collapse-item
            v-for="(item, key) in download[thisActive - 1]"
            :key="key"
          >
            <template slot="title">
              {{ item.title }}
              <el-button type="text" class="downcilck"
                ><a :href="item.link"><i class="el-icon-download"></i></a>
              </el-button>
            </template>
            <div v-for="itemT in item.data" :key="itemT" v-html="'-' + itemT">
              -更新1
            </div>
            <div v-html="'--' + item.time"></div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div v-if="thisActive == 3" class="software">
      <div class="menu bg-cover">
        <div>
          <div class="content">
            <p>Smart Superv</p>
            <h2>3D Visual</h2>
            <h3>
              It can directly transform the scene into a naked eye 3D resource
            </h3>
          </div>
          <a href="./static/software/3D-Visual.zip">
            <el-button size="medium " type="success">Download</el-button></a
          >
        </div>
        <div class="rigth">
          <img
            style="width: 55%; max-width: 100px"
            src="@/assets/img/product/software/3D-visual-logo.png"
            alt=""
          />
        </div>
      </div>
      <div class="detail-box">
        <p>
          Shenzhen Vechao realizes 3D effect through the combination of soft and
          hard way, only need to paste a 3D cell phone film, you can experience
          the 3D effect that can only be enjoyed in cinema anytime and anywhere.
          Vision 3D APP provides the display content of 3D video, including
          providing 3D education series, 3D film and television, ethnic film and
          television, etc.
        </p>
        <img src="@/assets/img/product/software/Visual-en.png" alt="" />
        <p>
          The core technology provided by Vision 3D, eye tracking technology, is
          to capture the position of the human eye through the front camera, and
          according to the user's eye position and pupil distance, customize and
          generate a reasonable perspective image matching the position of the
          human eye in real time. 3D display algorithm can adapt to different
          environments, and the algorithm based on neural network automatically
          learns, so that the image can follow the human eye to ensure
          uniformity when viewing, which greatly improves the 3D The 3D display
          effect is stable and comfortable to watch.
        </p>
      </div>

      <div class="line-B"></div>
      <div class="update">
        <div class="update-title">Update log:</div>
        <el-collapse accordion>
          <el-collapse-item
            v-for="(item, key) in download[thisActive - 1]"
            :key="key"
          >
            <template slot="title">
              {{ item.title }}
              <el-button type="text" class="downcilck"
                ><a :href="item.link"><i class="el-icon-download"></i></a>
              </el-button>
            </template>
            <div v-for="itemT in item.data" :key="itemT" v-html="'-' + itemT">
              <!-- -更新1 -->
            </div>
            <div v-html="'--' + item.time"></div>
          </el-collapse-item>
        </el-collapse>

      </div>
    </div>
    <div v-if="thisActive == 4" class="software">
      <div class="menu bg-cover">
        <div>
          <div class="content">
            <p>Smart Superv</p>
            <h2>Visual Health Cloud</h2>
            <h3>Test your vision online and protect your eyes</h3>
          </div>
          <a href="./static/software/Visual-Health-Cloud.zip">
            <el-button size="medium " type="success">Download</el-button></a
          >
        </div>
        <div class="rigth">
          <img
            style="width: 55%; max-width: 100px"
            src="@/assets/img/product/software/Visual-Health-Cloud-logo.png"
            alt=""
          />
        </div>
      </div>
      <div class="detail-box">
        <p>
          Vision Health Cloud is jointly developed by Zhongshan Eye Center and
          Shenzhen Holographic Information Technology Development Co. The
          product is provided by Zhongshan Eye Center with professional
          ophthalmology guidance, visual health big data algorithms and clinical
          application trials, and Shenzhen Holographic Information Technology
          Development Co.
        </p>
        <img src="@/assets/img/product/software/eyes.jpeg" alt="" />
        <p>
          Visual Health Cloud expands the scenario of visual function
          examination to the family, realizes early monitoring and screening of
          children's visual function development, as well as the supervision of
          youth vision development, so as to achieve the goal of early
          prevention and early diagnosis and treatment of ophthalmic diseases,
          and helps improve the level of visual health of the whole population.
          The product is mainly used for visual health screening and long-term
          trend statistics. In case of abnormal examination results, you should
          go to the hospital for more in-depth and detailed examination as soon
          as possible. The self-test results are for reference only and are not
          to be used as the basis for treatment during the final consultation.
          Accurate results need to be determined by professional optometry.
        </p>
      </div>

      <div class="line-B"></div>
      <div class="update">
        <div class="update-title">Update log:</div>
        <el-collapse accordion>
          <el-collapse-item
            v-for="(item, key) in download[thisActive - 1]"
            :key="key"
          >
            <template slot="title">
              {{ item.title }}
              <el-button type="text" class="downcilck"
                ><a :href="item.link"><i class="el-icon-download"></i></a>
              </el-button>
            </template>
            <div v-for="itemT in item.data" :key="itemT" v-html="'-' + itemT">
              -更新1
            </div>
            <div v-html="'--' + item.time"></div>
          </el-collapse-item>
        </el-collapse>

      </div>
    </div>
    <div
      v-if="thisActive != 3 && thisActive != 1 && thisActive != 4"
      style="margin-top: 15px"
    >
      No Data
    </div>
  </div>
</template> 

<script>
import oldToNew from "../CommonConponent/software/oldToNew.vue";
// import pdf from './static/software/up.pdf'
export default {
  components: { oldToNew },
  data() {
    return {
      thisActive: this.$route.query.id || "1",
      download: [
        [
          {
            id: 1,
            title: "3DPlugIn_1217",
            time: "2021-12-27",
            link: "./static/software/3DPlugIn.zip",
            data: ["Modify known bugs"],
          },
        ],
        [{}],
        [
          {
            id: 1,
            title: "3D Visual 1.29.3(56)",
            time: "2021-12-27",
            link: "./static/software/3D-Visual.zip",
            data: [
              "1.Update SDK",
              "2.Modify known bugs",
              "3.Remove the eye protection test function",
            ],
          },
          {
            id: 2,
            title: "3D Visual 1.29.3(55)",
            time: "2021-11-21",
            link: "./static/software/3D-Visual.zip",
            data: ["Modify known bugs"],
          },
        ],
        [
          {
            id: 1,
            title: "Visual Health Cloud 2.6.1(14)",
            time: "2022-3-22",
            link: "./static/software/Visual-Health-Cloud.zip",
            data: [
              "1. replaced the sdk",
              "2. Add local pictures",
              "3. Modify the problem of flashback of vision measurement",
              "4. UI text modification",
            ],
          },
          {
            id: 2,
            title: "Visual Health Cloud 2.6.0(13)",
            time: "2022-2-21",
            link: "./static/software/Visual-Health-Cloud.zip",
            data: ["Modify known bugs"],
          },
        ],
      ],
    };
  },
  methods: {
    more() {
      this.download.push({
        title: "正式版v1.0.1.0",
        time: "2022年3月8日11:35:36",
        link: "./static/software/3DPlugIn.zip",
      });
    },
  },
  mounted() {},
  watch: {
    $route(to, from) {
      this.thisActive = to.query.id;
    },
  },
};
</script>

<style lang="less" scoped>
.software {
  text-align: left;
  .detail-box {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    p {
      text-align: left;
      padding: 20px;
      text-indent: 16px;
      color: #7e7979;
      line-height: 24px;
    }
    img {
      width: 75%;
      max-width: 750px;
    }
  }
  .menu {
    width: 100%;
    background: #047fdd;
    margin-top: -1px;
    display: flex;
    align-items: center;
    padding: 50px 0 50px 10%;
    // background-image: url(https://pc1.gtimg.com/act/gj/images/banner4-061928f99b.jpg);
    .rigth {
      flex: 1;
      text-align: center;
    }
    .content {
      color: rgb(247, 242, 242);
      padding: 20px;

      p {
        font-size: 16px;
      }
      h2 {
        font-size: 50px;
        margin: 8px 0 0 -2px;
      }
      h3 {
        font-size: 18px;
      }
    }
    a {
      // display: inline-block;
      padding: 20px 0 0 20px;
      margin-top: 20px;
      .el-button--medium {
        padding: 25px 60px;
        font-size: 28px;
      }
    }
  }

  .update {
    padding: 0 30px 30px;
    .update-title {
      font-size: 18px;
      padding: 15px 0;
    }
    .downcilck {
      margin-left: 10px;
      a {
        color: #047fdd;
      }
    }
    ul {
      font-size: 14px;
      margin-left: 15px;
      width: 70%;
      li {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        a {
          &:hover {
            color: #047fdd;
          }
        }
      }
    }
  }
}
</style>