<template>
  <div class="detail">
      <h2>unity applications:</h2>
    <el-row type="flex" justify="center" class="detail-container">
      <el-col :xs="24" :sm="8">
        <div class="detail-more">
          <div class="detail-more-img">
            <img
              src="@/assets/img/product/software/unityPlayIn_start.png"
              alt=""
            />
          </div>
          <div class="">
            <h5 class="">Original picture</h5>
            <span
              >The initial picture taken by the original camera</span
            >
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="4" class="display-center">
        <div class="">
            <i class="el-icon-right"></i>
        </div>
      </el-col>
      <el-col :xs="24" :sm="8">
        <div class="detail-more">
          <div class="detail-more-img">
            <img
              src="@/assets/img/product/software/unityPlayIn_end.png"
              alt=""
            />
          </div>
          <div class="">
            <h5 class="">Transformed image</h5>
            <span
              >Using the Unity 3DPlugIn, users are free to convert the original scene resources into the display mode needed for the naked eye 3D screen and apply it to our hardware products.</span
            >
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "oldToNew",
  data() {
    return {
      productDataList: [
        [
          {
            id: 1,
            index: "de1",
            img: require("@/assets/img/product/software/unityPlayIn_start.png"),
            span: { xs: 24, sm: 8 },
            title: "27 Inches Glasses-free 3D AD Player",
            detail:
              "27 inches glasses-free 3D AD player provides outstanding 3D effects. It is an ideal product for advertisement and supporting both landscape and portrait modes. With embedded content management system, it allows centralized content updating, schedulin",
          },
          {
            id: 1,
            img: require("@/assets/img/product/software/unityPlayIn_end.png"),
            span: { xs: 24, sm: 8 },
            title: "32 inches Glasses-free 3D Smart Display",
            index: "home3",
            detail:
              "An ideal entry level products with fully embedded playback platform and software.Upon that, it perfect fits for window display, in-store product promotion and many other business scenarios.",
          },
        ],
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.o-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.detail {
  // padding: 50px 0 0 0;
//   display: flex;
  // width: 942px;
  h2{
      padding: 30px ;
  }
  margin: 0 auto;
  .detail-container {
    // padding: 0 2%;
    max-width: 100%;
    margin: 0 auto;
    > div {
      // margin-bottom: 30px;
      // height: 427.6px;
      display: block;
      padding: 15px;
      // height: 100%;
    }
    .display-center{
        display: flex;
        align-items: center;
    justify-content: center;
    i{
        font-size: 38px;
    }
    }
    .detail-more {
      position: relative;
      background: #f8f8f8;
      padding: 15px;
      // height: 100%;
      text-align: center;
    //   &:hover {
    //     box-shadow: 4px 4px 10px #e5f1fd;
    //   }
      .detail-more-img {
        width: 100%;
        // height: 235px;
        margin-bottom: 10px;
        background-size: contain;
        img {
          max-width: 100%;
          max-height: 100%;
          display: block;
          margin: auto;
        }
      }
    }
    h5 {
      margin: 0;
      font-size: 18px;
      font-weight: normal;
      line-height: 30px;
      text-transform: uppercase;
      font-family: Arial, Helvetica, sans-serif;
      color: #333;
      display: block;
    }
    span {
      color: #8a8fa0;
      line-height: 24px;
      font-size: 12px;
    }
  }
}
</style>